import * as React from 'react';

import { BIContext } from './BIContext';
import { BIProviderPropsMap } from './interfaces';

export const BIProvider: React.FC<BIProviderPropsMap> = props => {
  const { children } = props;
  return (
    <BIContext.Provider
      value={{
        beforeAndAfterAction: props.beforeAndAfterAction,
        screenOpen: props.screenOpen,
        tabOpen: props.tabOpen,
        startTimeOfRender: props.startTimeOfRender,
        memberWebAppButtonClick: props.memberWebAppButtonClick,
        reportSSRIsRendered: props.reportSSRIsRendered,
      }}
    >
      {children}
    </BIContext.Provider>
  );
};
