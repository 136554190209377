import * as React from 'react';
import { withPayment } from '../../../../contexts/Payment/withPayment';
import { withUser } from '../../../../contexts/User/withUser';
import { IPaymentProviderProps } from '../../../../contexts/Payment/paymentProviderPropsMap';
import { IUserProviderProps } from '../../../../contexts/User/UserProvider';
import { ICashierPaymentsApi } from '@wix/cashier-payments-widget';
import { withLocation } from '../../../../contexts/Location/withLocation';
import { ILocationProviderProps } from '../../../../contexts/Location/LocationProvider';
import { Pages } from '../../../../contexts/Location/LocationContext';
import { withChallengeData } from '../../../../contexts/ChallengeDataProvider/withChallengeData';
import { IChallengeDataProps } from '../../../../contexts/ChallengeDataProvider/challengeDataProviderPropsMap';
import { applyProviders } from '../../../../services/applyProviders';
import { withExperiments } from '../../../../contexts/Experiments/ExperimentsContext';
import { IExperimentsContext } from '../../../../contexts/Experiments/interfaces';
import { PaymentWidget } from './PaymentWidget';

export interface IContainerProps {
  onApiReady(api: ICashierPaymentsApi): void;
  onWidgetLoaded(): void;
}

let requestInProgress = false;

type IPaymentContainerProps = IPaymentProviderProps &
  IUserProviderProps &
  IContainerProps &
  IExperimentsContext &
  ILocationProviderProps &
  IChallengeDataProps;

export const PaymentWidgetContainer: React.FC<IContainerProps> = applyProviders(
  (props: IPaymentContainerProps) => {
    const challengeId = props.challengeData?.challenge?.id;

    if (!challengeId) {
      return null;
    }

    if (!props.user || !props.orderId) {
      props.goToPage({
        pageId: Pages.Details,
        challengeId,
      });
      return null;
    }

    if (!props.participant && !requestInProgress) {
      requestInProgress = true;
    }

    return (
      <PaymentWidget
        ownerId={props.ownerId}
        appId={props.appId}
        userId={props.user.id}
        instanceId={props.instanceId}
        locale={props.language}
        orderId={props.orderId}
        onApiReady={props.onApiReady}
        onWidgetLoaded={props.onWidgetLoaded}
        paymentComplete={() => {
          props.goToPage({
            pageId: Pages.ThankYou,
            challengeId,
          });
        }}
      />
    );
  },
  [withPayment, withChallengeData, withLocation, withUser, withExperiments],
);

PaymentWidgetContainer.displayName = 'PaymentWidgetContainer';
