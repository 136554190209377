import * as React from 'react';
import { IUser } from '@wix/native-components-infra/dist/src/types/types';
import {
  ParticipantState,
  V1Participant,
} from '@wix/ambassador-challenge-service-web/types';
import {
  JoinFunction,
  CancelJoinRequestFunction,
  LeaveTheChallengeFunction,
} from './userContextInterface';

// https://www.wix.com/corvid/reference/wix-users.html#LoginOptions
export interface IPromptLogin {
  mode?: 'login' | 'signup';
  lang?: string;
}

export enum UserState {
  VISITOR = 'VISITOR',
  MEMBER = 'MEMBER',
}

export type IUserType = UserState | ParticipantState;

export interface IUserContext {
  user: IUser;
  userType: IUserType;
  participant?: V1Participant;
  promptLogin(options?: IPromptLogin): Promise<any>;
  join: JoinFunction;
  cancelJoinRequest: CancelJoinRequestFunction;
  leaveTheChallenge: LeaveTheChallengeFunction;
  userTypeHandlers: {
    [index: string]: (userType: IUserType) => boolean;
  };
}

export const UserContext = React.createContext<IUserContext>({
  user: null,
  userType: null,
  promptLogin: null,
  participant: null,
  join: null,
  userTypeHandlers: null,
  cancelJoinRequest: null,
  leaveTheChallenge: null,
});

export const UserConsumer = UserContext.Consumer;
