import * as React from 'react';
import {
  ListParticipantStepsResponse,
  ParticipantStepState,
  V1ParticipantStep,
} from '@wix/ambassador-challenge-service-web/types';

export interface IParticipantStepsContext {
  participantSteps: ListParticipantStepsResponse;
  updateParticipantSteps(currentDate?: Date): void;
  isParticipantStepsLoading: boolean;
  updateParticipantStepStatus(
    steps: V1ParticipantStep[],
    stepId: string,
    status: ParticipantStepState,
  ): void;
}

export const ParticipantStepsContext = React.createContext<
  IParticipantStepsContext
>({
  participantSteps: null,
  updateParticipantSteps: () => {},
  isParticipantStepsLoading: false,
  updateParticipantStepStatus: () => {},
});

export const ParticipantStepsConsumer = ParticipantStepsContext.Consumer;
