import React from 'react';
import ReactDOM from 'react-dom';

import { withGeneralData } from '../../contexts/GeneralDataProvider/withGeneralData';
import { IGeneralDataContext } from '../../contexts/GeneralDataProvider/GeneralDataContext';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

import styles from './Toast.st.css';

export type ToastType = 'success' | 'error';

export interface IToastProps {
  opened?: boolean;
  type?: ToastType;
  onClose(): void;
}

export interface IToastState {
  opened: boolean;
}

type IInnerToastProps = IToastProps & IGeneralDataContext;

class _Toast extends React.PureComponent<IInnerToastProps, IToastState> {
  static defaultProps: Omit<IToastProps, 'onClose'> = {
    type: 'success',
  };

  state = {
    opened: false,
  };

  el = null;
  toastRoot = null;
  timer = null;

  componentDidMount() {
    this.el = document && document.createElement('div');
    this.toastRoot = document && document.getElementById('toast-root');
    this.toastRoot && this.toastRoot.appendChild(this.el);

    if (this.props.opened) {
      this.setState({ opened: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opened !== this.props.opened) {
      this.setState({ opened: this.props.opened });
    }

    if (this.state.opened) {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.handleClose, 5000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.toastRoot &&
      this.toastRoot.removeChild &&
      this.toastRoot.removeChild(this.el);
  }

  handleClose = () => {
    this.setState({ opened: false });
    this.props.onClose();
  };

  render() {
    const { formFactor } = this.props;
    const mobile = formFactor === 'Mobile';

    const toastContent = (
      <section {...styles('root', { mobile, type: this.props.type })}>
        <p className={styles.content}>{this.props.children}</p>
        <button onClick={this.handleClose} className={styles.button}>
          <CloseIcon />
        </button>
      </section>
    );

    return (
      (this.state.opened && ReactDOM.createPortal(toastContent, this.el)) ||
      null
    );
  }
}

export const Toast = withGeneralData<IToastProps>(_Toast);
