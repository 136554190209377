import { contextFactory } from '../../services/contextFactory';
import { IExperimentsContext, IExperimentsProviderProps } from './interfaces';
import { Challenges } from '../../editor/types/Experiments';
import { isDisabled, isEnabled } from '../../services/experiments';

const { ContextProvider, withConsumer } = contextFactory<
  IExperimentsContext,
  IExperimentsProviderProps
>({
  defaultValues: {
    experiments: {} as any,
    disabled: () => true,
    enabled: () => false,
  },
  displayName: 'ExperimentsProvider',
  propsToContext(props): IExperimentsContext {
    return {
      ...props,
      enabled(experiment: Challenges): boolean {
        return isEnabled(props.experiments[experiment]);
      },
      disabled(experiment: Challenges): boolean {
        return isDisabled(props.experiments[experiment]);
      },
    };
  },
});

export const ExperimentsProvider = ContextProvider;
export const withExperiments = withConsumer;
