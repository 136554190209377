import * as React from 'react';
import { ListChallengesResponse } from '@wix/ambassador-challenge-service-web/types';

export interface IChallengesListDataContext {
  challengesListData: ListChallengesResponse;
}

export const ChallengesListDataContext = React.createContext<
  IChallengesListDataContext
>({
  challengesListData: null,
});

export const ChallengesListDataConsumer = ChallengesListDataContext.Consumer;
