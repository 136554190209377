import * as React from 'react';

import { PaymentContext } from './PaymentContext';
import { IPaymentProviderProps } from './paymentProviderPropsMap';

export const PaymentProvider: React.FC<IPaymentProviderProps> = props => {
  const { orderId, instanceId, appId, language, ownerId } = props;
  return (
    <PaymentContext.Provider
      value={{ orderId, instanceId, appId, language, ownerId }}
    >
      {props.children}
    </PaymentContext.Provider>
  );
};
