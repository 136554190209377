import React from 'react';
import { SettingsConsumer } from './BIContext';

export function withBI<Props>(Component: any) {
  return props => {
    return (
      <SettingsConsumer>
        {({ ...rest }) => {
          return <Component {...(props as Props)} {...rest} />;
        }}
      </SettingsConsumer>
    );
  };
}
