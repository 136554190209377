import * as React from 'react';

import { SettingsContext } from './SettingsContext';

export interface ISettingsProviderProps {
  settings: any; //todo think of make it generic
}

export const SettingsProvider: React.FC<ISettingsProviderProps> = props => {
  return (
    <SettingsContext.Provider
      value={{
        settings: props.settings,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};
