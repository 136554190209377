import * as React from 'react';
import { VIEW_MODE } from '../../config/constants';
import { listenToHeightChanges } from 'wix-height-updater';
import i18n from '../../config/i18n';
import { I18nextProvider } from 'react-i18next';
import { IProvidersProps } from './intefaces';
import { withWixSDKWidget } from '../../services/withWixSDK';
import { IWixSDKViewerEnvironmentContext } from 'yoshi-flow-editor-runtime/build';
import { SettingsProvider } from '../Settings/SettingsProvider';
import { UserProvider } from '../User/UserProvider';
import { LocationProvider } from '../Location/LocationProvider';
import { ChallengeDataProvider } from '../ChallengeDataProvider/challengeDataProvider';
import { PaymentProvider } from '../Payment/PaymentProvider';
import { GeneralDataProvider } from '../GeneralDataProvider/GeneralDataProvider';
import { BIProvider } from '../BI/BIProvider';
import { ChallengesListDataProvider } from '../ChallengesListDataProvider/challengesListDataProvider';
import { ExperimentsProvider } from '../Experiments/ExperimentsContext';
import { InviteLinkProvider } from '../InviteLink/InviteLinkContext';
import { PaidPlansProvider } from '../PaidPlans/paidPlansContext';
import { ToastProvider } from '../ToastContext/ToastProvider';
import { ParticipantStepsDataProvider } from '../ParticipantStepsDataProvider/ParticipantStepsDataProvider';
import { ResolveStepDataProvider } from '../ResolveStep/ResolveStepDataProvider';

class Providers extends React.Component<
  IProvidersProps &
    IWixSDKViewerEnvironmentContext & {
      Component: any;
    }
> {
  static displayName = 'ChallengeProviders';

  componentDidMount() {
    this.props.reportSSRIsRendered && this.props.reportSSRIsRendered();
    this.props.screenOpen && this.props.screenOpen();

    if (this.props.viewMode === VIEW_MODE.Editor) {
      listenToHeightChanges(this.props.Wix, window);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.renderingEnv === 'backend' || !prevProps.renderingEnv) &&
      this.props.renderingEnv === 'browser'
    ) {
      this.onWidgetRendered();
    }
  }

  onWidgetRendered() {
    this.props.reportSSRIsRendered();
  }

  render() {
    const {
      translations, // i18Next
      language, // i18Next, Payment
      experiments, // Experiments
      challengeData, // Challenge
      beforeAndAfterAction, // BI
      screenOpen, // BI
      tabOpen, // BI
      startTimeOfRender, // BI
      reportSSRIsRendered, // BI
      memberWebAppButtonClick, // BI
      appDefinitionId, // General
      viewMode, // General
      renderingEnv, // General
      formFactor, // General
      path, // Location
      query, // Location
      location, // Location
      goToPage, // Location
      goToExternalUrl, // Location
      user, // User
      userType, // User
      participant, // User
      promptLogin, // User
      join, // User
      cancelJoinRequest, // User
      leaveTheChallenge, // User
      userTypeHandlers, // User
      appId, // Payment
      instanceId, // Payment
      orderId, // Payment
      Component,
      challengesListData,
      participantSteps, // Participant Steps List
      updateParticipantSteps, // Participant Steps List
      isParticipantStepsLoading, // Participant Steps List
      updateParticipantStepStatus, // Participant Steps List
      isResolveStepRequestInProgress, // Resolve Step
      resolveStep, // Resolve Step
      resolveStepError, // Resolve Step
      settings,
      ownerId,
      showToast,
      instance,
      ...rest
    } = this.props;

    return (
      <I18nextProvider i18n={i18n({ language, translations })}>
        <BIProvider
          beforeAndAfterAction={beforeAndAfterAction}
          screenOpen={screenOpen}
          tabOpen={tabOpen}
          startTimeOfRender={startTimeOfRender}
          reportSSRIsRendered={reportSSRIsRendered}
          memberWebAppButtonClick={memberWebAppButtonClick}
        >
          <GeneralDataProvider
            appDefinitionId={appDefinitionId}
            viewMode={viewMode}
            renderingEnv={renderingEnv}
            formFactor={formFactor}
            instance={instance}
          >
            <LocationProvider
              path={path}
              query={query}
              location={location}
              goToPage={goToPage}
              goToExternalUrl={goToExternalUrl}
            >
              <ExperimentsProvider
                experiments={this.props.experiments}
                disabled={this.props.disabled}
                enabled={this.props.enabled}
              >
                <UserProvider
                  user={user}
                  userType={userType}
                  participant={participant}
                  promptLogin={promptLogin}
                  join={join}
                  cancelJoinRequest={cancelJoinRequest}
                  leaveTheChallenge={leaveTheChallenge}
                  userTypeHandlers={userTypeHandlers}
                >
                  <PaymentProvider
                    ownerId={ownerId}
                    appId={appId}
                    instanceId={instanceId}
                    orderId={orderId}
                    language={language}
                  >
                    <ChallengeDataProvider challengeData={challengeData}>
                      <ChallengesListDataProvider
                        challengesListData={challengesListData}
                      >
                        <SettingsProvider settings={settings}>
                          <InviteLinkProvider
                            inviteLink={this.props.inviteLink}
                          >
                            <PaidPlansProvider
                              eligiblePlans={this.props.eligiblePlans}
                              userPaidPlans={this.props.userPaidPlans}
                            >
                              <ToastProvider showToast={showToast}>
                                <ParticipantStepsDataProvider
                                  participantSteps={participantSteps}
                                  updateParticipantSteps={
                                    updateParticipantSteps
                                  }
                                  isParticipantStepsLoading={
                                    isParticipantStepsLoading
                                  }
                                  updateParticipantStepStatus={
                                    updateParticipantStepStatus
                                  }
                                >
                                  <ResolveStepDataProvider
                                    isResolveStepRequestInProgress={
                                      isResolveStepRequestInProgress
                                    }
                                    resolveStep={resolveStep}
                                    resolveStepError={resolveStepError}
                                  >
                                    <Component {...rest} />
                                  </ResolveStepDataProvider>
                                </ParticipantStepsDataProvider>
                              </ToastProvider>
                            </PaidPlansProvider>
                          </InviteLinkProvider>
                        </SettingsProvider>
                      </ChallengesListDataProvider>
                    </ChallengeDataProvider>
                  </PaymentProvider>
                </UserProvider>
              </ExperimentsProvider>
            </LocationProvider>
          </GeneralDataProvider>
        </BIProvider>
      </I18nextProvider>
    );
  }
}

export function withProviders(ProvidedComponent) {
  return withWixSDKWidget(function (props: any) {
    return <Providers {...props} Component={ProvidedComponent} />;
  });
}
