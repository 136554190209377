import * as React from 'react';
import { ChallengeDataContext } from './ChallengeDataContext';
import { IChallengeDataProps } from './challengeDataProviderPropsMap';

export const ChallengeDataProvider: React.FC<IChallengeDataProps> = props => {
  return (
    <ChallengeDataContext.Provider
      value={{ challengeData: props.challengeData }}
    >
      {props.children}
    </ChallengeDataContext.Provider>
  );
};
