import * as React from 'react';
import { ILocation } from '@wix/native-components-infra/dist/src/types/types';

export enum Pages {
  Details = 'challenge_page',
  Payment = 'Challenge Payment',
  ThankYou = 'Thank You Page',
}

export const PageSlugs: Record<Pages, string> = {
  [Pages.Details]: 'challenge-page',
  [Pages.Payment]: 'challenge-payment',
  [Pages.ThankYou]: 'challenge-thanks',
};

export interface IGoToPageOptions {
  pageId: Pages;
  challengeId?: string;
  isAbsoluteURL?: boolean;
}

export interface ILocationContext {
  location: ILocation;
  path: string[];
  query: {
    [key: string]: string;
  };
  goToExternalUrl(url: string): void;
  goToPage(options: IGoToPageOptions): void;
}

export const LocationContext = React.createContext<ILocationContext>({
  location: null,
  path: [],
  query: {},
  goToExternalUrl: () => {},
  goToPage: () => {},
});

export const LocationContextConsumer = LocationContext.Consumer;
