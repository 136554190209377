import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';

export enum ButtonNames {
  OneAppInfo = 'one_app_info',
  ViewChallenge = 'viewChallenge',
  BackToChallenge = 'backToChallenge',
  LoginAndJoinToTheChallenge = 'loginAndJoinToTheChallenge',
  JoinToTheChallenge = 'joinToTheChallenge',
  GoToOneApp = 'goToOneApp',
  OpenDotsMenu = 'OpenDotsMenu',
  StepComplete = 'StepComplete',
  LeaveTheChallenge = 'LeaveTheChallenge',
  LeaveTheChallengeAtModal = 'LeaveTheChallengeModal',
  CancelLeaveTheChallengeModal = 'CancelLeaveTheChallengeModal',
}
export interface BIProviderPropsMap {
  reportSSRIsRendered(): void;
  beforeAndAfterAction<T>(
    type: ActionTypes,
    fn: (actionId: string) => Promise<T>,
  ): Promise<T>;
  screenOpen(): void;
  tabOpen(options: { tabName: TabNames; challengeId?: string }): Promise<any>;
  startTimeOfRender: number;
  memberWebAppButtonClick(options: {
    buttonName: ButtonNames;
    challengeId?: string;
  }): Promise<any>;
}

export enum ActionTypes {
  CHALLENGE_JOIN = 'challenge_join',
  PAYMENT = 'payment_request',
  CREATE_PAYMENT_ORDER_ID = 'create_payment_order_id',
  JOIN_WITH_PAYMENT = 'join_with_payment',
  JOIN_REQUEST_CREATE = 'join_request_create',
  JOIN_REQUEST_CANCEL = 'join_request_cancel',
  STEP_COMMENT_SAVE = 'step_comment_save',
  FEEDBACK_SEND = 'feedback_send',
  FEEDBACK_SAVE = 'feedback_save',
  NOTIFICATION_SETTINGS_SET = 'notification_settings_set',
  STEP_COMPLETE = 'step_complete',
  CHALLENGE_COMPLETE = 'challenge_complete',
  CHALLENGE_LEFT = 'challenge_left',
  LEAVE_THE_CHALLENGE = 'leave_the_challenge',
}

export enum ScreenNames {
  PaymentPage = 'PaymentPage',
  ChallengePage = 'ChallengeDetails',
  ChallengePageForParticipant = 'ChallengePageForParticipant',
  ChallengeListWidget = 'ChallengesList',
  ThankYouPage = 'ThankYouPage',
}

export enum TabNames {
  ChallengeParticipantSchedule = 'ChallengeParticipantSchedule',
  ChallengeParticipantOverview = 'ChallengeParticipantOverview',
}

export interface IBiSettings {
  screenName: ScreenNames;
  preventSendChallengeIdFormLocation?: boolean;
  getItemsCount(config: IWidgetControllerConfig): number | Promise<number>;
}
