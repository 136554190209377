import { contextFactory } from '../../services/contextFactory';
import { IPaidPlansContext, IPaidPlansProviderProps } from './interfaces';

const { ContextProvider, withConsumer } = contextFactory<
  IPaidPlansContext,
  IPaidPlansProviderProps
>({
  defaultValues: {
    userPaidPlans: [],
    eligiblePlans: [],
  },
  displayName: 'InviteLinkProvider',
  propsToContext(props): IPaidPlansContext {
    return {
      ...props,
    };
  },
});

export const PaidPlansProvider = ContextProvider;
export const withPaidPlans = withConsumer;
