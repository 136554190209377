import * as React from 'react';
import { CashierPaymentsWidgetLazy } from '@wix/cashier-payments-widget/dist/src/lazy';
import {
  ICashierPaymentsApi,
  ICashierPaymentsConfiguration,
} from '@wix/cashier-payments-widget';

export interface ISingPaymentIntegrationProps {
  appId: string;
  userId: string;
  instanceId: string;
  locale: string;
  ownerId: string;
  orderId: string;
  onApiReady(api: ICashierPaymentsApi): void;
  paymentComplete(): void;
  onWidgetLoaded(): void;
}

interface IPaymentWidgetState {
  statusReported: boolean;
}

export class PaymentWidget extends React.Component<
  ISingPaymentIntegrationProps,
  IPaymentWidgetState
> {
  static displayName = 'CashierPaymentWidget';
  private widgetAPI: ICashierPaymentsApi;

  state = { statusReported: false };

  private readonly handleWidgetRef = async (ref) => {
    if (ref) {
      this.widgetAPI = await ref.getApi();
      this.props.onApiReady(this.widgetAPI);
      await this.widgetAPI.expand();
    }
  };

  private readonly handleLoadingUpdate = (subject, status) => {
    if (
      subject === 'CashierPaymentsWidget' &&
      status &&
      !this.state.statusReported
    ) {
      this.setState({
        statusReported: true,
      });
      this.props.onWidgetLoaded();
    }
  };

  render() {
    const configuration: ICashierPaymentsConfiguration = {
      appId: this.props.appId as any,
      appInstanceId: this.props.instanceId,
      locale: this.props.locale,
      visitorId: this.props.userId,
      snapshotId: this.props.orderId,
      siteOwnerId: this.props.ownerId,
    };

    return (
      <CashierPaymentsWidgetLazy
        ref={this.handleWidgetRef}
        debug={true}
        onLoadingChanged={this.handleLoadingUpdate}
        onError={(...args) => console.info('onError', args)}
        onPaymentMethodsLoaded={(...args) =>
          console.info('onPaymentMethodsLoaded', args)
        }
        onWalletPaymentComplete={(...args) =>
          console.info('onWalletPaymentComplete', args)
        }
        onIsValidChanged={(...args) => console.info('onIsValidChanged', args)}
        onPaymentStart={(...args) => console.info('onPaymentStart', ...args)}
        onPaymentComplete={(paymentMethod, info) => {
          if (info.status === 'OK') {
            this.props.paymentComplete();
          }
        }}
        paymentMethodChanged={(...args) =>
          console.info('handlePaymentMethodChange', ...args)
        }
        configuration={configuration}
      />
    );
  }
}
