import * as React from 'react';
import styles from './PaymentCheckout.st.css';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { Divider } from 'wix-ui-tpa/Divider';

export interface PaymentCheckoutInfoProps {
  title: string;
  price: string;
  duration: string;
  showOneAppInfo(): void;
  t(key: string): string;
}

export const PaymentCheckout: React.FC<PaymentCheckoutInfoProps> = (props) => {
  return (
    <section {...styles('root', {}, {})}>
      <h1 className={styles.title}>{props.title}</h1>
      <time className={styles.duration}>{props.duration}</time>
      <Divider className={styles.separator} />
      <dl className={styles.priceContainer}>
        <td className={styles.priceTitle}>
          {props.t('payment.page.total-price')}
        </td>
        <dd className={styles.priceValue}>{props.price}</dd>
      </dl>
    </section>
  );
};
