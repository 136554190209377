import * as React from 'react';
import { BIProviderPropsMap } from './interfaces';

export interface IBIContext extends BIProviderPropsMap {}

export const BIContext = React.createContext<IBIContext>({
  startTimeOfRender: 0,
  beforeAndAfterAction: null,
  memberWebAppButtonClick: null,
  screenOpen() {},
  tabOpen: null,
  reportSSRIsRendered(): void {},
});

export const SettingsConsumer = BIContext.Consumer;
