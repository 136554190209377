import React from 'react';
import { UserConsumer } from './UserContext';

export function withUser<Props>(Component: any) {
  return props => {
    return (
      <UserConsumer>
        {value => {
          return <Component {...(props as Props)} {...value} />;
        }}
      </UserConsumer>
    );
  };
}
