import * as React from 'react';
import { withProviders } from '../../../contexts/main/withProviders';
import { ISinglePaymentPageProps, ISingPaymentPageState } from './interfaces';
import { PaymentWidgetContainer } from '../components/PaymentWidget/PaymentWidgetContainer';
import { PaymentCheckoutContainer } from '../components/PaymentCheckout/PaymentCheckoutContainer';
import styles from './Widget.st.css';
import { withGeneralData } from '../../../contexts/GeneralDataProvider/withGeneralData';
import { isMobile } from '../../../contexts/GeneralDataProvider/generalDataProviderPropsMap';
import { withUser } from '../../../contexts/User/withUser';
import { withLocation } from '../../../contexts/Location/withLocation';
import { withChallengeData } from '../../../contexts/ChallengeDataProvider/withChallengeData';

class PaymentPage extends React.Component<
  ISinglePaymentPageProps,
  ISingPaymentPageState
> {
  state = {
    cashierApi: null,
    paymentWidgetLoaded: false,
  };

  componentDidMount(): void {
    // if (!this.props.participant) {
    //   this.props.goToPage({
    //     pageId: Pages.Details,
    //     challengeId: this.props.challengeData.challenge.id,
    //   });
    //   return;
    // }
  }

  render() {
    const { formFactor, ...rest } = this.props;

    return (
      <main
        {...styles(
          'root',
          {
            mobile: isMobile(formFactor),
          },
          rest,
        )}
      >
        <PaymentWidgetContainer
          onApiReady={cashierApi => {
            this.setState({
              cashierApi,
            });
          }}
          onWidgetLoaded={() => {
            this.setState({
              paymentWidgetLoaded: true,
            });
          }}
        />

        {this.state.paymentWidgetLoaded && (
          <div className={styles.checkoutLayout}>
            <PaymentCheckoutContainer />
          </div>
        )}
      </main>
    );
  }
}

export default withProviders(
  withUser(withGeneralData(withLocation(withChallengeData(PaymentPage)))),
);
