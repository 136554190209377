import React from 'react';
import { LocationContextConsumer } from './LocationContext';

export function withLocation<Props>(Component: any) {
  return props => {
    return (
      <LocationContextConsumer>
        {value => {
          return <Component {...(props as Props)} {...value} />;
        }}
      </LocationContextConsumer>
    );
  };
}
