import React from 'react';
import { WixSDK } from 'yoshi-flow-editor-runtime';

export const withWixSDK = (Component: React.ComponentType) => props => (
  <WixSDK isEditor>
    {sdk => {
      return <Component {...props} Wix={sdk.Wix} />;
    }}
  </WixSDK>
);

export const withWixSDKWidget = (Component: React.ComponentType) => props => (
  <WixSDK isEditor={undefined}>
    {sdk => {
      return <Component {...props} Wix={sdk.Wix} />;
    }}
  </WixSDK>
);
