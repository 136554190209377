import * as React from 'react';
import {
  IWixWindowFormFactor,
  IWixWindowViewMode,
} from '@wix/native-components-infra/dist/src/types/types';

export interface IGeneralDataContext {
  viewMode: IWixWindowViewMode;
  renderingEnv: 'backend' | 'browser';
  formFactor: IWixWindowFormFactor;
  appDefinitionId: string;
  instance: string;
}

export const GeneralDataContext = React.createContext<IGeneralDataContext>({
  formFactor: null,
  renderingEnv: null,
  viewMode: null,
  appDefinitionId: null,
  instance: null,
});

export const GeneralDataContextConsumer = GeneralDataContext.Consumer;
