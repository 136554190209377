import * as React from 'react';
import { PaymentCheckout } from './PaymentCheckout';
import { withChallengeData } from '../../../../contexts/ChallengeDataProvider/withChallengeData';
import { IChallengeDataProps } from '../../../../contexts/ChallengeDataProvider/challengeDataProviderPropsMap';
import {
  serverPricingToClientPriceAndCurrencyString,
  serverTimelineTypeToClientTimelineString,
} from '@wix/challenges-web-library/dist/src';
import { translate } from 'react-i18next';
import get from 'lodash/get';

import { IWithTranslationProps } from '../../../../contexts/main/intefaces';
import { withLocation } from '../../../../contexts/Location/withLocation';
import { withBI } from '../../../../contexts/BI/withBI';
import { ILocationProviderProps } from '../../../../contexts/Location/LocationProvider';
import {
  BIProviderPropsMap,
  ButtonNames,
} from '../../../../contexts/BI/interfaces';
import { getCountPrefix } from '../../../../selectors/langCount';

export interface PaymentCheckoutContainerProps {}

type IPaymentCheckoutContainerProps = IChallengeDataProps &
  IWithTranslationProps &
  ILocationProviderProps &
  BIProviderPropsMap;

export const PaymentCheckoutContainer: React.FC<PaymentCheckoutContainerProps> = withLocation(
  withBI(
    translate()(
      withChallengeData((props: IPaymentCheckoutContainerProps) => {
        const challenge = props.challengeData.challenge;
        const lng = props.lng;
        const langPrefix = getCountPrefix(
          get(challenge.settings.timelineType, 'flexible.duration.value', 0),
          lng,
        );

        const durationString = serverTimelineTypeToClientTimelineString(
          challenge.settings.timelineType as any,
          props.lng,
          props.t,
          'challenge-card.duration-string.ongoing',

          `challenge-card.duration-string.flexible.days${langPrefix}`,

          `challenge-card.duration-string.flexible.weeks${langPrefix}`,
        );

        return (
          <PaymentCheckout
            t={props.t}
            showOneAppInfo={async () => {
              await props.memberWebAppButtonClick({
                buttonName: ButtonNames.OneAppInfo,
              });
              props.goToExternalUrl('https://www.wix.com/mobile/wix-app');
            }}
            title={challenge.settings.description.title}
            price={serverPricingToClientPriceAndCurrencyString(
              challenge.settings.pricing as any,
            )}
            duration={durationString}
          />
        );
      }),
    ),
  ),
);
