import memoize from 'lodash/memoize';
import i18next from 'i18next';
// bundle in fallback language
import messagesEn from '../assets/locales/challenges_editor_en.json';

declare var __webpack_public_path__: string;

export function asyncMessagesLoader(language: string) {
  if (language === 'en') {
    return Promise.resolve(messagesEn);
  }

  // locales are `fetch`ed and not `import`ed because
  // `bolt` SSR enviroment doesn't support webpack's `JsonpTemplatePlugin`
  // see also:
  //   https://github.com/wix-private/site-search/pull/369
  //   https://github.com/wix-private/site-search/commit/93a16dfbe1fcca9af7cc1abe88f0e0df222970c8
  return fetch(
    `${__webpack_public_path__}assets/locales/challenges_editor_${language}.json`,
  )
    .then(r => {
      if (!r.ok) {
        throw new Error(`Can't load locale: ${language}`);
      }

      return r.json();
    })
    .catch(() => {
      console.error(`Cannot resolve messages for ${language} language`);
      return messagesEn;
    });
}

export const i18nInstance = i18next.createInstance();

export interface I18nConfig {
  language: string;
  translations?: object;
  waitForReact?: boolean;
}

export default memoize(function i18n(params: I18nConfig) {
  const { language, translations, waitForReact } = params;
  const options: any = {
    lng: language,
    fallbackLng: 'en',
    keySeparator: false,
    react: {
      wait: !!waitForReact,
    },
  };

  if (translations) {
    options.resources = {
      [language]: {
        translation: translations,
      },
    };
  } else {
    i18nInstance.use({
      type: 'backend',
      read: async (lang, namespace, callback) => {
        return asyncMessagesLoader(lang)
          .then(t => callback(null, t))
          .catch(error => callback(error));
      },
    });
  }

  return i18nInstance.init(options);
});
