import * as React from 'react';

export interface ISettingsContext {
  settings: any;
}

export const SettingsContext = React.createContext<ISettingsContext>({
  settings: {},
});

export const SettingsConsumer = SettingsContext.Consumer;
