import { IWixWindowFormFactor } from '@wix/native-components-infra/dist/src/types/types';
import { IGeneralDataContext } from './GeneralDataContext';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';

export interface IGeneralDataProviderProps extends IGeneralDataContext {}

export function isMobile(formFactor: IWixWindowFormFactor): boolean {
  return formFactor === 'Mobile';
}

export function generalDataProviderPropsMap(
  flowAPI: ControllerFlowAPI,
): IGeneralDataProviderProps {
  const {
    viewMode,
    formFactor,
    rendering,
  } = flowAPI.controllerConfig.wixCodeApi.window;
  const { appParams } = flowAPI.controllerConfig;

  return {
    appDefinitionId: appParams.appDefinitionId,
    instance: appParams.instance,
    viewMode,
    formFactor,
    renderingEnv: rendering.env,
  };
}
