import * as React from 'react';
import { IGeneralDataContext, GeneralDataContext } from './GeneralDataContext';

export const GeneralDataProvider: React.FC<IGeneralDataContext> = props => {
  return (
    <GeneralDataContext.Provider
      value={{
        formFactor: props.formFactor,
        viewMode: props.viewMode,
        renderingEnv: props.renderingEnv,
        appDefinitionId: props.appDefinitionId,
        instance: props.instance,
      }}
    >
      {props.children}
    </GeneralDataContext.Provider>
  );
};
