import * as React from 'react';

export interface IResolveStepContext {
  isResolveStepRequestInProgress: boolean;
  resolveStep(
    stepId: string,
    complexity: number,
    comment: string,
  ): Promise<void>;
  resolveStepError: string;
}

export const ResolveStepContext = React.createContext<IResolveStepContext>({
  isResolveStepRequestInProgress: false,
  resolveStep: (() => {}) as any,
  resolveStepError: null,
});

export const ResolveStepConsumer = ResolveStepContext.Consumer;
