import React from 'react';
import { PaymentContextConsumer } from './PaymentContext';

export function withPayment<Props>(Component: any): React.FC<Props> {
  return props => {
    return (
      <PaymentContextConsumer>
        {value => {
          const { orderId, instanceId, appId, language, ownerId } = value;
          return (
            <Component
              {...props}
              orderId={orderId}
              instanceId={instanceId}
              appId={appId}
              language={language}
              ownerId={ownerId}
            />
          );
        }}
      </PaymentContextConsumer>
    );
  };
}
