import Experiments from '@wix/wix-experiments';
import { EXPERIMENTS_SCOPE } from '../config/constants';
import { IChallengeExperiments } from '../editor/types/Experiments';
import memoize from 'lodash/memoize';

export async function getChallengesExperiments(): Promise<
  IChallengeExperiments
> {
  return (await getExperimentsByScope(EXPERIMENTS_SCOPE)) as any;
}

export const getExperimentsByScope = memoize(async (scope: string) => {
  const experiments = new Experiments({
    scope,
    baseUrl: 'https://www.wix.com',
  });
  await experiments.ready();
  return experiments.all();
});

export function isEnabled(stringBool: string): boolean {
  return stringBool === 'true';
}

export function isDisabled(stringBool: string) {
  return !isEnabled(stringBool);
}
