import * as React from 'react';
import { IPaymentProviderProps } from './paymentProviderPropsMap';

export interface IPaymentContext extends IPaymentProviderProps {}

export const PaymentContext = React.createContext<IPaymentContext>({
  appId: '',
  instanceId: '',
  orderId: '',
  language: 'en',
  ownerId: '',
});

export const PaymentContextConsumer = PaymentContext.Consumer;
