import * as React from 'react';

interface ContextFactoryOptions<ContextProps, ProviderProps> {
  defaultValues: ContextProps;
  displayName: string;
  propsToContext(props: ProviderProps): ContextProps;
}

interface ContextFactoryReturnType<ProviderProps, ContextProps> {
  ContextProvider: React.FC<ProviderProps>;
  context: React.Context<ContextProps>;
  withConsumer(Component: any): any;
}

export function contextFactory<ContextProps, ProviderProps>(
  options: ContextFactoryOptions<ContextProps, ProviderProps>,
): ContextFactoryReturnType<ProviderProps, ContextProps> {
  const context = React.createContext<ContextProps>(options.defaultValues);
  context.displayName = options.displayName;

  const { Consumer, Provider } = context;
  const ContextProvider: React.FC<ProviderProps> = props => (
    <Provider value={options.propsToContext(props)}>{props.children}</Provider>
  );

  const withConsumer = (Component: any) => {
    return props => {
      return (
        <Consumer>
          {value => {
            return <Component {...(props as any)} {...value} />;
          }}
        </Consumer>
      );
    };
  };

  return {
    withConsumer,
    ContextProvider,
    context,
  };
}
