import React from 'react';

import { ToastContext, IToastContext } from './ToastContext';
import { Toast, ToastType } from '../../components-shared/Toast';

export interface IToastProviderProps extends IToastContext {}

export interface IToastProviderState {
  opened: boolean;
  content: any;
  theme: ToastType;
}

export class ToastProvider extends React.PureComponent<
  IToastProviderProps,
  IToastProviderState
> {
  state = {
    opened: false,
    content: null,
    theme: 'success' as ToastType,
  };

  toggleOpen = () => {
    this.setState({ opened: !this.state.opened });
  };

  render() {
    return (
      <ToastContext.Provider
        value={{
          showToast: (content, opts) => {
            this.setState({ content, theme: opts?.type, opened: true });
          },
        }}
      >
        <Toast
          type={this.state.theme}
          onClose={this.toggleOpen}
          opened={this.state.opened}
        >
          {this.state.content}
        </Toast>
        {this.props.children}
      </ToastContext.Provider>
    );
  }
}
